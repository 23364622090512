<template>
  <div class="storage">
    <transition name="bubble">
      <!--      <div v-if="loading">-->
      <!--        <base-loader class="stack-detail-view__loader-item" />-->
      <!--      </div>-->
      <!--      <page-block v-else class="vps-detail-view__block">-->
      <page-block class="storage-view__block">
        <main-card>
          <div class="addons__label medium-title">
            {{ $t('type') }}
          </div>
          <br />
          <div class="addons">
            <div class="addons__item">
              <!--              <div class="addons__label standart-title">{{ $t('container') }}</div>-->
              <div class="addons__label standart-text">{{ $t('container') }}</div>
              <div v-if="mode === 'base'" class="addons__value standart-text">
                {{ tariff.policy.IsPublic === true ? 'Публичный' : 'Приватный' }}
              </div>
              <div v-else class="addons__value standart-text">
                <div v-for="category of categories" :key="category.key" class="field-radiobutton">
                  <RadioButton
                    :id="category.key"
                    v-model="selectedCategory"
                    name="category"
                    :value="category"
                    class="addons__value-radio"
                  />
                  <label :for="category.key">{{ $t(category.name) }}</label>
                </div>
              </div>
            </div>
          </div>
          <br />

          <div class="addons">
            <div v-if="mode === 'base'" class="addons__item">
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Изменить"
                  icon="pi pi-pencil"
                  class="p-button-primary"
                  style="width: 130px"
                  @click="edit"
                ></Button>
              </div>
            </div>
            <div v-else class="addons__item">
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Сохранить"
                  icon="pi pi-check"
                  class="p-button-primary"
                  style="width: 130px"
                  @click="send"
                ></Button>
              </div>
              <div class="addons__item-btn">
                <Button
                  type="button"
                  label="Отмена"
                  icon="pi pi-times"
                  style="width: 130px"
                  class="p-button-danger"
                  @click="cancel"
                ></Button>
              </div>
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import RadioButton from 'primevue/radiobutton';
import providerChange from '@/mixins/providerChange';
import { OPStorage } from '@/models/OP/OPStorage';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import BaseInput from '@/components/BaseInput/BaseInput';
import modals from '@/mixins/modals';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
export default {
  name: 'ObjectsView',
  components: {
    MainCard,
    // InputNumber,
    // BaseInput,
    // InputText,
    RadioButton,
    Button,
  },
  mixins: [modals, showErrorOpenStackModal],
  props: {
    tariff: {
      type: OPStorage,
      required: true,
      validator: obj => obj instanceof OPStorage,
    },
    // id: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      mode: 'base',
      selectedCategory: null,
      currentSize: '',
      currentCount: '',
      categories: [
        { name: 'private', key: 'private' },
        { name: 'public', key: 'public-read' },
      ],
      formData: {},
      // isLoading: true,
    };
  },
  computed: {
    isPublic() {
      return this.tariff.policy.IsPublic === true;
    },
    id() {
      return this.$store.state.moduleStack.current;
    },
    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
  },
  watch: {
    // formData: {
    //   handler: function (event) {
    //     console.log(event);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    tariff: {
      handler: function (event) {
        console.log('tariff', event);
        if (event) {
          this.selectedCategory =
            event.policy.IsPublic === true
              ? { key: 'public-read', name: 'public' }
              : { key: 'private', name: 'private' };
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.updateFormData(this.tariff);
  },
  methods: {
    updateFormData(event) {
      console.log('updateFormData');
      if (event) {
        this.selectedCategory =
          event.policy.IsPublic === true
            ? { key: 'public-read', name: 'public' }
            : { key: 'private', name: 'private' };
      }
    },
    edit() {
      this.mode = 'edit';
    },
    cancel() {
      this.mode = 'base';
      this.selectedCategory =
        this.tariff.policy.IsPublic === true
          ? { key: 'public-read', name: 'public' }
          : { key: 'private', name: 'private' };
    },
    onChange(name, event) {
      console.log(name.name);
      this.formData[name] = event;
    },
    async send() {
      this.mode = 'base';
      const params = {
        name: this.tariff.name,
        policy: this.selectedCategory.key,
        key: this.s3key,
      };
      await this.$store
        .dispatch('moduleStack/setPolicyS3Bucket', params)
        // .then(() =>
        // this.showResModal(
        //   `Контейнеру <b>${this.tariff.name}</b> успешно установлен тип <b>${this.$t(
        //     this.selectedCategory.name
        //   )}</b> .`
        // )
        // )
        .then(async data => {
          console.log(data);
          await this.$store.dispatch('moduleStack/fetchProjectStorages', this.s3key);
        })
        .catch(e => this.showErrorS3(e));

      // return this.$store
      //   .dispatch('moduleStack/updateStorage', params)
      //   .then(data => {
      //     console.log(data);
      //   })
      //   .catch(e => {
      //     console.log(e);
      //   })
      //   .finally(async () => {});
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "os": "Источник",
      "name": "Имя",
      "cache": "Кэширование",
      "container": "Тип контейнера",
      "type": "Настройки конфиденциальности",
      "x-container-meta-quota-bytes": "Суммарный размер, ГБ",
      "x-container-meta-quota-count": "Количество, шт",
"text": "Для сброса лимита - уберите символы из соответствующих полей. Настройки лимитов применяются только при загрузке новых файлов и не влияют на уже хранящиеся файлы.",
      "sum": "Суммарный размер, ГБ",
      "currentSize": "Текущий объем контейнера",
      "currentCount": "Текущее количество объектов",
      "private": "Приватный",
      "public": "Публичный",
      "count": "Количество, шт",
      "limit": "Лимиты объектов контейнера",
      "port": "Входящий порт",
      "vppr": "Служебные порты",
      "cpu": "vCPU",
      "ram": "Память",
      "disk": "Локальный диск",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.storage {
  max-width: 50rem
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 130px;
      margin: 0.5em 0.75rem;
    }
  }
}

.addons {

  &__input{
   //min-width: 20rem
    height: 36px;
   width: 100%
  }
  &__value{
    min-width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;




  &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    //margin-top: 1.5rem;
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      //min-width: 160px;
      width: 130px;
      margin: 0.5em 0.75rem;
    }

    & + & {
      //margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        //border-top: 1px solid var(--border);
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
</style>
